import ReactPlayerLoader from "@brightcove/react-player-loader";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import usePaywall from "@/hooks/usePaywall";

const SEGMENT_COUNT = 6;

const Video = ({
  brightcoveVideoId,
  onPlay,
  onPause,
  playOnMount,
  onEnded,
  className = "",
  playing = false,
  hideControls = false,
  loop = false,
  muted = false,
  showProgressBar = false,
  hasPermissionToView = true,
  paywallSettings = {},
}) => {
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(playing);
  const [isPaused, setIsPaused] = useState(false);
  const [isTemporarilyPaused, setIsTemporarilyPaused] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [percentagePlayed, setPercentagePlayed] = useState(0);
  const [percentagePlayedStaggered, setPercentagePlayedStaggered] = useState(0);
  const videoRef = useRef(null);
  const { setPaywall } = usePaywall();

  // If playing prop changes, update the state of isPlaying
  useEffect(() => {
    if (!isReady) return;
    if (playing ? play() : pause()) setIsPlaying(playing);
  }, [isReady, playing]);

  const play = () => {
    videoRef.current.play();
  };

  const pause = () => {
    videoRef.current.pause();
  };

  const pushEvent = useCallback(
    (event, data = {}) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event, brightcoveVideoId, ...data });
    },
    [brightcoveVideoId]
  );

  useEffect(() => {
    if (isPlaying) pushEvent("playVideo");
  }, [isPlaying, pushEvent]);

  useEffect(() => {
    if (isEnded) pushEvent("endVideo");
    if (isPaused && !isEnded) pushEvent("pauseVideo");
  }, [isPaused, isEnded, pushEvent]);

  useEffect(() => {
    if (percentagePlayedStaggered < 100)
      pushEvent("videoProgress", { progress: percentagePlayedStaggered });
  }, [percentagePlayedStaggered, pushEvent]);

  const onSuccess = (success) => {
    videoRef.current = success.ref;

    // Set isReady to true
    setIsReady(true);

    // Hide controls if requested - this should be able to be done in the options prop
    // but isn't because of a known bug at https://github.com/brightcove/react-player-loader/issues/60
    if (hideControls) {
      success.ref.controlBar.hide();
    }

    success.ref.on("play", (e) => {
      if (!hasPermissionToView) {
        success.ref.pause();

        // Open a paywall
        setPaywall(paywallSettings);
      } else {
        setIsPlaying(true);
        setIsPaused(false);
        onPlay();
      }
    });

    success.ref.on("ended", (e) => {
      setIsEnded(true);
      setIsPlaying(false);
      onEnded();
    });

    success.ref.on("pause", (e) => {
      setIsPlaying(false);
      setIsPaused(true);
      onPause();
    });

    success.ref.on("timeupdate", (e) => {
      const { player } = e.target;

      if (!player) return;

      const timePlayed = player.duration() - player.remainingTime();
      const timePlayedPercentage = (timePlayed / player.duration()) * 100;

      setPercentagePlayed(timePlayedPercentage);

      if (timePlayedPercentage % 10 === 0 || timePlayedPercentage === 0) {
        setPercentagePlayedStaggered(timePlayedPercentage);
      }
    });

    success.ref.on("touchstart", function (e) {
      if (e.target.nodeName === "VIDEO") {
        if (success.ref.paused()) {
          play();
        } else {
          pause();
        }
      }
    });

    if (playOnMount) play();
  };

  // Make an arrary of
  const progressArray = Array.from(Array(SEGMENT_COUNT).keys());

  return (
    <>
      <ReactPlayerLoader
        accountId="6113368299001"
        onSuccess={onSuccess}
        playerId="default"
        embedId="default"
        videoId={brightcoveVideoId}
        attrs={{
          className: clsx("Video", className),
        }}
        playsInline
        options={{ playsinline: true, loop, muted }}
        loop={loop}
        muted={muted}
      />

      {showProgressBar && (
        <div
          className={clsx(
            "Video__progressBar",
            "absolute bottom-6 left-6 right-6 h-2 rounded-full bg-black/0 transition-opacity duration-300 ease-out",
            {
              "opacity-0": !isPlaying && !isTemporarilyPaused,
              "opacity-100": isPlaying,
            }
          )}
        >
          <div
            className={clsx(
              "Video__progressBarSegments",
              "flex h-2 w-full items-center justify-between space-x-1.5"
            )}
          >
            {progressArray.map((item, index) => {
              const percentage = (100 / SEGMENT_COUNT) * index;

              return (
                <button
                  onMouseDown={() => {
                    setIsTemporarilyPaused(true);

                    // Set the video to the correct time
                    videoRef.current.currentTime(
                      videoRef.current.duration() * (percentage / 100)
                    );

                    pause();
                    videoRef.current.player().bigPlayButton.hide();
                  }}
                  onMouseUp={() => {
                    setIsTemporarilyPaused(false);
                    videoRef.current.player().bigPlayButton.show();
                    play();
                  }}
                  onMouseLeave={() => {
                    if (isTemporarilyPaused) {
                      videoRef.current.player().bigPlayButton.show();
                      play();
                    }
                  }}
                  key={index}
                  className={clsx(
                    "Video__progressBarSegment",
                    "block h-1 w-full rounded-full bg-white transition-all duration-100 hover:h-2",
                    {
                      "opacity-25 hover:opacity-75 active:opacity-100":
                        percentagePlayed <= percentage,
                      "opacity-100": percentagePlayed >= percentage,
                    }
                  )}
                ></button>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

Video.defaultProps = {
  brightcoveVideoId: "",
  onPlay: () => null,
  onPause: () => null,
  onEnded: () => null,
  playOnMount: false,
};

export default Video;
