import clsx from "clsx";
import ReactHtmlParser from "react-html-parser";

const TextBlock = ({ text, noGutter = false, size = "lg" }) => (
  <div
    className={clsx(`TextBlock`, "flex justify-center", {
      "px-6 lg:px-12": !noGutter,
      "px-0": noGutter,
    })}
  >
    <div
      className={clsx(`TextBlock__content`, "", {
        "body-text-xl": size === "xl",
        "body-text-lg": size === "lg",
        "body-text": size === "base",
        "body-text-sm": size === "sm",
      })}
    >
      {ReactHtmlParser(text)}
    </div>
  </div>
);

export default TextBlock;
